<template>
  <div id="bill-info" class="bill-info">
    <el-form :model="formData" ref="billForm" label-width="80px" label-align="right" :rules="rules">
      <el-form-item label="名称:" prop="titleName">
        <el-input type="text" v-model="formData.titleName" placeholder="抬头名称" />
      </el-form-item>
      <el-form-item label="税号:" prop="taxpayerNumber">
        <el-input type="text" v-model="formData.taxpayerNumber" placeholder="纳税人识别号" />
      </el-form-item>
      <el-form-item label="单位地址:">
        <el-input type="text" v-model="formData.companyAddress" placeholder="公司地址" />
      </el-form-item>
      <el-form-item label="电话号码:">
        <el-input type="text" v-model="formData.companyPhone" placeholder="公司电话" />
      </el-form-item>
      <el-form-item label="开户银行:">
        <el-input type="text" v-model="formData.bankName" placeholder="开户银行" />
      </el-form-item>
      <el-form-item label="银行账号:">
        <el-input type="text" v-model="formData.bankAccount" placeholder="银行账户" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {},
      rules: {
        titleName: [{ required: true, message: '请输入抬头名称', trigger: 'blur' }],
        taxpayerNumber: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    this.getBillInfo()
  },

  methods: {
    handleSubmit() {
      this.$refs.billForm.validate((valid) => {
        if (valid) {
          this.$axios.post('/zuwu-api/web/enterprise/addInvoiceInfo', this.formData).then(({ data }) => {
            if (data.code == 200) {
              this.$message.success('保存开票信息成功！')
            } else {
              this.$message.warning(data.msg)
            }
          })
        }
      })
    },
    getBillInfo() {
      this.$axios.post('/zuwu-api/web/enterprise/getEnterpriseInvoice').then(({ data }) => {
        if (data.code == 200) {
          this.formData = data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.bill-info {
  padding: 30px 10%;
}
</style>
