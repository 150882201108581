<template>
  <div class="collect">
    <MyMenu :menuname="'user'"></MyMenu>
    <div class="w1200">
      <div class="User-leftNav">
        <div class="leftNav-photo">
          <img src="https://t7.baidu.com/it/u=2939661005,3253462827&fm=193" />
          <div class="leftNav-name">{{ this.$store.getters.getUser }}</div>
        </div>
        <h2>
          <i class="el-icon-user-solid"></i>
          个人信息
        </h2>
        <ul>
          <li @click="User" :class="ishover == 'user' ? 'hover' : ''">我的信息</li>
          <!-- <li @click="realname" :class="ishover == 'realname' ? 'hover' : ''">实名认证</li> -->
        </ul>
        <h2>
          <i class="el-icon-s-order"></i>
          订单中心
        </h2>
        <ul>
          <li @click="order" :class="ishover == 'order' ? 'hover' : ''">我的订单</li>
        </ul>
        <ul>
          <li @click="myBill" :class="ishover == 'myBill' ? 'hover' : ''">我的发票</li>
        </ul>

        <h2>
          <i class="el-icon-s-custom"></i>
          企业信息
        </h2>
        <ul>
          <li @click="supplement" :class="ishover == 'supplement' ? 'hover' : ''">我的企业</li>
          <li @click="addInfo" :class="ishover == 'addInfo' ? 'hover' : ''">补充资料</li>
        </ul>
        <h2>
          <i class="el-icon-s-tools"></i>
          系统设置
        </h2>
        <ul>
          <li @click="address" :class="ishover == 'address' ? 'hover' : ''">收货地址</li>
        </ul>
        <ul>
          <li @click="billInfo" :class="ishover == 'billInfo' ? 'hover' : ''">开票信息</li>
        </ul>
      </div>
      <div class="User-rightPage" v-loading="loading">
        <!-- 我的订单 -->
        <div class="preview" ref="order" v-if="ishover == 'order'">
          <router-view></router-view>
        </div>
        <!-- 收货地址 -->
        <div class="shippingaddress" ref="address" v-if="ishover == 'address'">
          <MyAddress></MyAddress>
        </div>
        <!-- 我的发票 -->
        <div class="my-bill" ref="myBill" v-if="ishover == 'myBill'">
          <my-bill></my-bill>
        </div>
        <!-- 开票信息 -->
        <div class="bill-info" ref="billInfo" v-if="ishover == 'billInfo'">
          <bill-info></bill-info>
        </div>

        <!-- 实名认证 -->
        <div class="realname" ref="realname" v-if="ishover == 'realname'">
          <div v-if="UserAuthImg">
            <h2>请在手机端扫码操作实名认证</h2>
            <img :src="UserAuthImg" v-if="UserAuthImg" />
            <div class="btn" @click="examine">操作完成</div>
          </div>
        </div>

        <div class="user" ref="user" v-if="ishover == 'user'">
          <user-info></user-info>
        </div>

        <!-- 企业添加 -->
        <div class="supplement" ref="supplement" v-if="ishover == 'supplement'">
          <supplement :source="'User'" @materials="materials"></supplement>
        </div>

        <!-- 补充资料 -->
        <div class="addInfo" ref="addInfo" v-if="ishover == 'addInfo'">
          <addInfo :source="'User'"></addInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAddress from '../../components/MyAddress.vue'
import BillInfo from '../../components/billInfo.vue'
import MyBill from '../../components/MyBill.vue'
import supplement from '../Order/item/supplement.vue'
import addInfo from '../Order/item/addInfo.vue'
import userInfo from './item/userInfo.vue'

export default {
  components: {
    MyAddress,
    supplement,
    addInfo,
    BillInfo,
    MyBill,
    userInfo
  },
  data() {
    return {
      collectList: [],
      ishover: 'user',
      loading: true,
      UserAuthImg: '',
      orderdetailState: false,
      ordermanageState: true,
      orderdetailId: ''
    }
  },
  watch: {
    $route(to) {
      // 对路由变化作出响应...
      if (to.name == 'order') {
        this.order()
      }
    }
  },
  created() {
    this.loading = true
    // if (this.$route.params.name) {
    //   this[this.$route.params.name]()
    // }else
    if (this.$route.name) {
      if (this.$route.params.name) {
        this[this.$route.params.name]()
      } else {
        this[this.$route.name]()
      }
    } else {
      this.ishover = 'user'
    }
  },

  // },
  methods: {
    // 我的订单
    order() {
      this.$router.push({ path: '/User/order' })
      this.ishover = 'order'
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 我的发票
    myBill() {
      this.$router.push({ path: '/User/myBill' })
      this.ishover = 'myBill'
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    orderdetail() {
      this.$router.push({ path: '/User/order' })
      this.ishover = 'order'
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    // 我的地址
    address() {
      this.$router.push({ path: '/User/address' })
      this.ishover = 'address'
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },

    // 开票信息
    billInfo() {
      this.$router.push({ path: '/User/billInfo' })
      this.ishover = 'billInfo'
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 实名认证
    realname() {
      this.loading = true
      this.$router.push({ path: '/User/realname' })
      this.$axios.get('/zuwu-api/webOfficial/officialUserAuth', {}).then(({ data }) => {
        this.ishover = 'realname'
        setTimeout(() => {
          this.loading = false
        }, 500)
        if (data.code == 200) {
          this.UserAuthImg = data.data
        } else {
          this.$alert(data.msg, '温馨提醒', {
            confirmButtonText: '确定',
            callback: () => {
              this.User()
            }
          })
        }
      })
    },
    examine() {
      this.$axios.get('/zuwu-api/webOfficial/getUserAuthResult').then(() => {
        if (this.ishover == 'realname') {
          this.realname()
        }
      })
    },
    // 个人信息
    User() {
      this.$router.push({ path: '/User' })
      this.ishover = 'user'
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 补充资料
    materials({ msg, isShow }) {
      if (isShow) {
        this.$alert(msg, '企业状态', {
          confirmButtonText: '确定',
          callback: () => {
            location.reload()
          }
        })
      } else {
        location.reload()
      }
    },
    // 企业信息
    supplement() {
      this.$router.push({ path: '/User/supplement' })
      this.ishover = 'supplement'
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    // 补充企业资料
    addInfo() {
      this.$router.push({ path: '/User/addInfo' })
      this.ishover = 'addInfo'
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
}
</script>
<style>
.collect {
  background-color: #f5f5f5;
  overflow: hidden;
}
.personal {
  width: 1160px;
  margin: 30px auto 30px;
  padding: 20px;
  text-align: center;
}

.shippingaddress {
  height: 600px;
  padding: 20px 0 0 20px;
  box-sizing: border-box;
}

.realname {
  overflow: hidden;
  text-align: center;
  height: 700px;
}
.realname h2 {
  padding: 50px 0 80px;
  font-size: 24px;
  font-weight: normal;
}
.realname .btn {
  width: 300px;
  height: 50px;
  cursor: pointer;
  margin: 20px auto 0;
  text-align: center;
  color: #fff;
  background: #1e95d4;
  font-size: 18px;
  line-height: 50px;
}
</style>
