<template>
  <div id="myBill" class="my-bill">
    <div class="search">
      <div class="btns">
        <div class="search-item">
          <span>申请日期：</span>
          <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div class="search-btn">
          <el-button type="primary" @click="handleApply">申请</el-button>
          <el-button @click="handleReset">重置</el-button>
          <el-button type="primary" @click="getTableData(1)">查询</el-button>
        </div>
      </div>
      <el-radio-group v-model="invoiceStatus" @change="getTableData(1)">
        <el-radio-button :label="0">待开票</el-radio-button>
        <el-radio-button :label="1">已开票</el-radio-button>
      </el-radio-group>
    </div>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%" height="500">
        <el-table-column prop="applyTime" label="申请时间" width="160" align="center"></el-table-column>
        <el-table-column prop="name" label="开票信息">
          <template slot-scope="scope">
            <div class="bill-info">
              <div class="infi-item">
                <span>名称：</span>
                {{ scope.row.titleName }}
              </div>
              <div class="infi-item">
                <span>税号：</span>
                {{ scope.row.taxpayerNumber }}
              </div>
              <div class="infi-item">
                <span>单位地址：</span>
                {{ scope.row.companyAddress }}
              </div>
              <div class="infi-item">
                <span>电话号码：</span>
                {{ scope.row.companyPhone }}
              </div>
              <div class="infi-item">
                <span>开户银行：</span>
                {{ scope.row.bankName }}
              </div>
              <div class="infi-item">
                <span>银行账户：</span>
                {{ scope.row.bankAccount }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="开票备注" width="180"></el-table-column>
        <el-table-column prop="invoiceType" label="发票类型" width="120" align="center"></el-table-column>
        <el-table-column prop="invoiceVoucher" label="发票下载" fixed="right" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.invoiceVoucher" @click="handleDownload(scope.row)">点击下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="handleChangePage"></el-pagination>
      </div>
    </div>

    <el-dialog title="申请开票" :visible.sync="applyBillShow" width="500px" :append-to-body="true" :close-on-click-modal="false">
      <el-form :model="applyForm" ref="applyForm" size="small" label-width="100px" :rules="rules">
        <el-form-item label="名称:" prop="titleName">
          <el-input v-model="applyForm.titleName" placeholder="抬头名称" />
        </el-form-item>
        <el-form-item label="税号:" prop="taxpayerNumber">
          <el-input v-model="applyForm.taxpayerNumber" placeholder="纳税人识别号" />
        </el-form-item>
        <el-form-item label="单位地址:" prop="companyAddress">
          <el-input v-model="applyForm.companyAddress" placeholder="公司地址" />
        </el-form-item>
        <el-form-item label="电话号码:" prop="companyPhone">
          <el-input v-model="applyForm.companyPhone" placeholder="公司电话" />
        </el-form-item>
        <el-form-item label="开户银行:" prop="bankName">
          <el-input v-model="applyForm.bankName" placeholder="开户银行" />
        </el-form-item>
        <el-form-item label="银行账号:" prop="bankAccount">
          <el-input v-model="applyForm.bankAccount" placeholder="银行账户" />
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType">
          <el-select v-model="applyForm.invoiceType" placeholder="发票类型" style="width:100%;">
            <el-option :value="0">普票</el-option>
            <el-option :value="1">专票</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票备注:" prop="remark">
          <el-input type="textarea" v-model="applyForm.remark" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="发票备注" />
        </el-form-item>
      </el-form>
      <div class="submit">
        <el-button size="medium" @click="handleCancel">取消</el-button>
        <el-button type="primary" size="medium" @click="submitApply">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateRange: [],
      invoiceStatus: 0,
      tableData: [],
      current: 1,
      total: 0,
      size: 10,
      // 申请开票所用数据
      applyBillShow: false,
      applyForm: {},
      rules: {
        titleName: [{ required: true, message: '请输入抬头名称', trigger: 'blur' }],
        taxpayerNumber: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        companyAddress: [{ required: true, message: '请输入单位地址', trigger: 'blur' }],
        companyPhone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入bankName', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入银行账号', trigger: 'blur' }],
        invoiceType: [{ required: true, message: '请选择发票类型', trigger: 'change' }]
      }
    }
  },

  mounted() {
    this.getTableData()
  },

  methods: {
    getTableData(current = 0) {
      if (current == 1) {
        this.current = 1
      }
      let [startTime, endTime] = this.dateRange || []
      let reqParams = {
        current: this.current,
        size: this.size,
        invoiceStatus: this.invoiceStatus,
        startTime: startTime || '',
        endTime: endTime || ''
      }
      this.$axios.post('/zuwu-api/web/enterprise/selectInvoiceApplyRecordPage', reqParams).then(({ data }) => {
        if (data.code == 200) {
          this.tableData = data.data.records
          this.total = data.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      })
    },
    handleApply() {
      this.getBillInfo()
      this.applyBillShow = true
    },
    // 确认申请开票
    submitApply() {
      this.$refs.applyForm.validate((valid) => {
        if (valid) {
          this.$axios.post('/zuwu-api/web/enterprise/applyForInvoice', this.applyForm).then(({ data }) => {
            let { code, msg } = data
            if (code == 200) {
              this.$message.success(msg)
              this.handleCancel(true)
            } else {
              this.$message.warning(msg)
            }
          })
        }
      })
    },
    handleCancel(refresh = false) {
      this.applyForm = {}
      this.applyBillShow = false
      refresh && this.getTableData(1)
    },
    handleReset() {
      this.dateRange = []
      this.invoiceStatus = 0
      this.current = 1
      this.getTableData()
    },
    handleDownload(row) {
      let { invoiceVoucher } = row
      console.log(invoiceVoucher)
      if (invoiceVoucher) {
        let list = invoiceVoucher.split(',')
        list.forEach((item) => {
          window.open(item)
        })
      }
    },
    getBillInfo() {
      this.$axios.post('/zuwu-api/web/enterprise/getEnterpriseInvoice').then(({ data }) => {
        if (data.code == 200) {
          this.applyForm = data.data
        }
      })
    },
    handleChangePage(current) {
      this.current = current
      this.getTableData()
    }
  }
}
</script>

<style scoped>
.my-bill {
  padding: 20px;
}
.search {
  padding-bottom: 20px;
}
.btns {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.submit {
  text-align: center;
}
.pagination {
  text-align: center;
  padding: 10px 0 10px;
}
</style>
