<template>
  <div class="userInfo">
    <el-form :model="userInfo" label-suffix="：" label-width="100px">
      <el-form-item label="手机号码">
        <span>{{ userInfo.mobile }}</span>
      </el-form-item>
      <el-form-item label="注册时间">
        <span>{{ userInfo.registerTime }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'JuuzuGuanwangUserInfo',

  data() {
    return {
      userInfo: {}
    }
  },

  mounted() {
    this.getUserInfo()
  },

  methods: {
    getUserInfo() {
      this.$axios.get('/zuwu-api/web/userInfo/myInformation', {}).then(({ data }) => {
        if (data.code == 200) {
          this.userInfo = data.data
        }
      })
    }
  }
}
</script>
<style scoped>
.userInfo {
  min-height: 700px;
  padding-top: 20px;
  box-sizing: border-box;
}
</style>
