<template>
  <div class="addInfo">
    <div v-if="currentpage == 1">
      <div class="haved" v-if="enterpriselist.length > 0">
        <div class="title">已有企业信息</div>
        <div class="Businesscard" v-for="(item, index) in enterpriselist" :key="index" @click="handleAddInfo(item)">
          <div class="arrows"></div>
          <div class="name">{{ item.enterpriseName }}</div>
          <span>统一社会信用代码：{{ item.socialCreditCode }}</span>
          <span>法人姓名：{{ item.legalPerson }}</span>
          <span>法人身份证号：{{ item.legalPersonIdCardNumber }}</span>
          <span>法人手机号：{{ item.legalPersonPhone }}</span>
          <span>法人联系地址：{{ item.address }}</span>
          <span>担保人：{{ item.guarantorType == 0 ? '法人' : item.guarantorType == 1 ? '代办人' : item.guarantorType }}</span>
          <span v-if="item.contractDownloadUrl" @click="handleViewContract(item.contractDownloadUrl)">
            协议：
            <span style="display:inline;color:#0984e3">《租赁框架协议》</span>
            <el-tooltip class="item" effect="dark" content="点击下载" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </span>
          <el-button type="primary" v-else class="signContract" @click="getSignedStatusAndUrl(item.id)">签署租赁框架协议</el-button>
        </div>
      </div>
      <div v-else>
        尚未绑定企业信息，无法补充资料
      </div>
    </div>
    <div v-if="currentpage == 2">
      <materials :enterpriseId="enterpriseinfo.id" source="addInfo" :contractSignStatus="contractSignStatus" @confirmSign="handleRefresh"></materials>
    </div>
  </div>
</template>

<script>
import materials from './materials.vue'
export default {
  name: 'JuuzuGuanwangAddInfo',
  components: { materials },
  props: ['source'],
  data() {
    return {
      currentpage: 1,
      enterpriselist: [],
      enterpriseinfo: {},
      // 合同签署状态
      contractSignStatus: false,
      // 控制按钮是否被点击状态
      btnLoading: false
    }
  },

  created() {
    this.getEnterpriseInfoList()
  },
  mounted() {},

  methods: {
    // 查看企业信息
    getEnterpriseInfoList() {
      this.$axios
        .get('/zuwu-api/web/enterprise/getEnterpriseInfoList')
        .then((res) => {
          if (res.data.code == 200) {
            var data = res.data.data
            this.enterpriselist = data
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    handleAddInfo(row) {
      let { contractDownloadUrl } = row
      this.contractSignStatus = contractDownloadUrl ? true : false
      console.log('获取企业详情', row)
      this.enterpriseinfo = row
      this.currentpage = 2
    },
    handleViewContract(contractDownloadUrl) {
      window.open(contractDownloadUrl)
    },
    async getSignedStatusAndUrl(enterpriseId) {
      setTimeout(() => {
        this.$confirm('是否已完成框架协议的签署', '提示', {
          confirmButtonText: '已完成',
          cancelButtonText: '未完成'
        })
          .then(() => {
            this.getEnterpriseInfoList()
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: '请点击签署租赁框架协议进行签署！',
              type: 'warning'
            })
          })
      }, 2000)
      let { data } = await this.$axios.get('/zuwu-api/web/enterprise/getAgreementIsSignSuccess?enterpriseId=' + enterpriseId)
      if (data.code == 200 && !data.data) {
        let { data: signedData } = await this.$axios.post('/zuwu-api/web/contract/getAgreementContractUrl', { enterpriseId })
        if (signedData.code == 200) {
          window.open(signedData.data.signUrl, '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: signedData.msg
          })
        }
      }
    },
    handleRefresh() {
      this.currentpage = 1
      this.getEnterpriseInfoList()
    }
  }
}
</script>
<style scoped>
.addInfo {
  min-height: 700px;
  padding: 10px;
  box-sizing: border-box;
}
.haved {
  padding: 0 25px 0;
}
.haved .title {
  font-size: 18px;
  line-height: 32px;
  text-indent: 2rem;
}
.Businesscard {
  width: 45%;
  margin: 0 0.5% 10px;
  display: inline-block;
  border: 1px solid #1e95d4;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 260px;
  vertical-align: top;
  cursor: pointer;
  border-radius: 5px;
  background: linear-gradient(360deg, #d2f4ff 0%, #fdfdfd 100%);
}

.Businesscard.hover {
  background: linear-gradient(360deg, #1e95d4 0%, #6ebadd 100%);
  color: #fff;
}
.hover {
  background: linear-gradient(360deg, #1e95d4 0%, #6ebadd 100%);
  color: #fff;
}
.Businesscard .name {
  margin: 5px auto;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.Businesscard span {
  display: block;
  margin-top: 2px;
}
.signContract {
  width: 100%;
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
</style>
